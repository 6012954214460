

export const HOST = "https://api.takengo.ekosmartafrica.com"
// export const HOST = "http://localhost:8095";
export const BASE_URL=`${HOST}/api`
export const keyAuth = '@takengo2023'
export const baseUrl = `${BASE_URL}/`
export const METHODS = {
    POST: 'POST',
    GET: 'GET',
    HEAD: 'HEAD',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
    OPTIONS: 'OPTIONS',
};
const AUTH = "/auth/users"
const COURSES = "/courses"

export const ENDPOINTS = {
    SING_IN: `${AUTH}/login`,
    SIGN_UP_CNI: `${AUTH}/with-identity`,
    SIGN_UP: `${AUTH}`,
    REFRESH_TOKEN: `${AUTH}/refresh`,
    VERIFY_OTP: `${AUTH}/otp/verify`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
    CONFIRM_RESET_PASSWORD: `${AUTH}/reset-password/confirm`,

    COURSES: `${COURSES}`,
};
export const maxRetries = 2
export const keepDataFor = 24*60*60
export const isRealValue = (obj) => obj && obj !== null && obj !== undefined

export const getObjectAsSearchParameter = (jsonObject) => {
    let str = '', i = 0;
    if (isRealValue(jsonObject)) {
        for (const [key, value] of Object.entries(jsonObject)) {
            let param = `${key}=${value}`
            i === 0 ? str = `?${param}` : str += `&${param}`
            i++
        }
    }
    return str
}
// const CryptoJS = require("crypto-js")
// const password = 'l5JmP+G0/1zB%;r8B8?2?2pcqGcL^3:YoUsHoP2022$%4&&*()_++!@`@!`'
// const tokenType = 'Bearer '

// export const crypted = (text) => CryptoJS.AES.encrypt(text, password)
// export const decrypted = (text) => (CryptoJS.AES.decrypt(text, password)).toString(CryptoJS.enc.Utf8)

export const crypted = (text) =>text
export const decrypted = (text) => text

export const toString = (json) => JSON.stringify(json)
export const toJson = (string) => JSON.parse(string)
export const cryptKey = (key) => key
export const saveItem = (key, value) => localStorage.setItem(cryptKey(key), crypted(value))
export const getItem = (key) => !isRealValue(localStorage.getItem(key)) ? null : decrypted((localStorage.getItem(key))?.toString())
export const deleteItem = (key) => localStorage.removeItem(cryptKey(key))

export const deleteAuth = (key) => localStorage.removeItem(cryptKey(key))
export const setAuthUser = (auth) => saveItem(keyAuth, toString(auth))
export const getAuth = () => toJson(getItem(cryptKey(keyAuth))) || null
export const updateAuth = (accessToken) => { let auth = getAuth(); auth.accessToken = accessToken; setAuthUser(auth); }
export const getUser = () => getAuth()?.user
export const getId = () => getUser()?._id
export const getToken = () => getAuth()?.accessToken
export const getRefreshToken = () => getAuth()?.refreshToken
export const isLogged = () => isRealValue(getAuth())
export const authorization = () => getToken()
export const refreshToken = () => getRefreshToken()
export const clearStorage = () => localStorage.clear()