import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../..";
import { deleteAuth, getAuth, isRealValue, setAuthUser, toJson } from "../api";
import { handleAuthentication, loggedOut } from "../features/authSlice";


export let getId = null
export let token = ""
export let refreshToken = null
export let expiredRefreshToken = 0
export let expiredToken = 0


export function useAuthentication() {
    const { authorization } = useSelector(state => state.auth)

    const availableAuthentication = async () => {
        const result = getAuth()
        // console.log('result', result)
        if (!isRealValue(result)) { dispatch(loggedOut()); deleteAuth(); }
        else {
            // const auth = toJson(result);
            const auth = result;
            getId = auth?.user?.id;
            token = auth?.accessToken;
            refreshToken = auth?.refreshToken;
            expiredRefreshToken = auth?.expiredRefreshToken;
            expiredToken = auth?.expiredToken;

            // console.log('auth', auth)
            setAuthUser(auth)
            dispatch(handleAuthentication(auth));
        }
    }

    useEffect(() => {
        const wait = async() => {
            // console.log('waiting')
            await  availableAuthentication()
        }
       wait()
    }, [authorization])

    return { availableAuthentication }

}
