import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl, ENDPOINTS, METHODS, setAuthUser } from '.'
import { dispatch } from '../..'
import { handleAuthentication } from '../features/authSlice'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        signIn: builder.mutation({
            query(body) {
                return {
                    url: `${ENDPOINTS?.SING_IN}`,
                    method: `${METHODS?.POST}`,
                    body,
                }
            },
            transformResponse: (result) => {
                setAuthUser(result)
                dispatch(handleAuthentication(result))
                return result
            },
            keepUnusedDataFor: 0,
        }),
    }),
})

export const {
    useSignInMutation,

} = authApi