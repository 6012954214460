import React from 'react';
import { LockClosedIcon, UserIcon } from "@heroicons/react/24/outline";
import InputFlatBorder from "../components/InputFlatBorder";
import welcome from '../assets/welcome1.png'
import BannerHeader from "../components/BannerHeader";
import { useSignInMutation } from "../app/api/authApi";
import { useAuthentication } from '../app/hook/useAuthentication';
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function SignInFormSplit() {

  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const { availableAuthentication } = useAuthentication()
  const onchangeUsername = (e) => {
    setUsername(e.target.value)
  }
  const onchangePassword = (e) => {
    setPassword(e.target.value)
  }
  const [signIn, { isLoading, error }] = useSignInMutation()
  const handleLogin = (ev) => {
    ev.preventDefault()
    const data = { password, username }
    signIn(data).unwrap().then((resp) => {
      availableAuthentication()
      window.location.href = '/users'
    }).catch(e => {
      // console.error('e', e)
    })
  }


  return (
    <React.Fragment>
      <BannerHeader />
      <div className="flex items-center lg:grid lg:grid-cols-2 h-screen divide-x gap-1 ">
        <div className="flex flex-1 flex-col mx-auto container justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-10 xl:px-14">
          <div className="mx-auto w-full ">
            <div>
              <img
                className="h-12 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
              <h2 className="mt-6 text-5xl text-center font-bold tracking-tight text-indigo-600">Connexion</h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={handleLogin} className="space-y-6"  >
                  <InputFlatBorder onChange={onchangeUsername} value={username}
                    label={"Username"}
                    rightIcon={<UserIcon className="w-6 h-6 text-orange-300" />}
                  />

                  <InputFlatBorder
                    label={"Password"} onChange={onchangePassword} value={password}
                    type="password"
                    rightIcon={<LockClosedIcon className="w-6 h-6 text-orange-300" />}
                  />
                  {error && <span style={{color: "red", marginTop: 7}}>{"Pseudo ou mot de passe incorrecte"}</span>}
                  <div className="max-w-xl mx-auto mt-24 " >
                    <button
                      type="submit"
                      onPress = {()=>navigate(`/Users`)}
                      className="flex h-[45px] w-full justify-center items-center rounded-lg border border-transparent bg-indigo-600 py-2 mt-20 px-4 text-sm font-medium text-white shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Connexion
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-full h-full flex-1 lg:flex lg:flex-col lg:items-center lg:px-34 lg:py-24 ">
          <img
            className="inset-0 w-1/2"
            src={welcome}
            alt=""
          />
          <h3 className="mt-24 text-3xl text-center font-bold tracking-tight text-indigo-600" >Rentabilisez votre voyage</h3>
          <h4 className="mt-8 text-xl text-center font-bold tracking-tight text-gray-300" >
            Rien de mieux que de gagner de l'argent <br /> En faisant ses chemin habituels.
          </h4>
        </div>
      </div>
    </React.Fragment>
  )
}
