import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { authApi } from './api/authApi'
import { userApi } from './api/userApi'
import authSlice from './features/authSlice'



const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true
    })
      .concat(
        authApi.middleware,
        userApi.middleware,
      ),
})

setupListeners(store.dispatch)

export default store