import React, { useState } from 'react'
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useValidateOrInvalidateSettingUserMutation } from '../app/api/userApi';
import LoadingComponent from './LoadingComponent';

const ValidateAccount = ({ person = {} }) => {
    const [Edit, setEdit] = useState(false);

    const [validateOrInvalidateSettingUser, { isLoading }] =
    useValidateOrInvalidateSettingUserMutation();
  const validOrInvalid = (idUser, validate) => {
    validateOrInvalidateSettingUser({ idUser, validate })
      .then((r) => {
        console.log("r", r);
        setEdit(false);
      })
      .catch((e) => {
        setEdit(false);
        console.error(e);
      });
  };
  

  return (
    <div className='relative flex flex-row space-x-4 items-center py-4 pl-3 pr-4 text-sm font-medium text-right justify-evenly' >
{person?.enabled && !Edit ? (
                       <React.Fragment>
                         <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Actif
                        </span>
                        <span onClick={()=>setEdit(true)} class="inline-flex cursor-pointer items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Edit</span>
                       </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div
                            className="flex items-center justify-center w-10 h-10 bg-red-500 rounded-full "
                            onClick={() => validOrInvalid(person?.id, false)}
                          >
                            <XMarkIcon className="w-6 h-6 text-white" />
                          </div>
                          <div
                            className="flex items-center justify-center w-10 h-10 bg-green-500 rounded-full "
                            onClick={() => validOrInvalid(person?.id, true)}
                          >
                            <CheckIcon className="w-6 h-6 text-white" />
                          </div>
                        </React.Fragment>
                      )}
                      <LoadingComponent open = {isLoading} />
    </div>
  )
}

export default ValidateAccount