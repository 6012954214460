
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isRealValue } from "../app/api";
import { useValidateOrInvalidateSettingUserMutation } from "../app/api/userApi";
import ModalFlat from "./ModalFlat";
import ValidateAccount from "./ValidateAccount";

const people = [
  {
    name: "Lindsay Walton",
    phone: "758362649",
    email: "lindsay.walton@example.com",
    balance: "374 038 CFA",
    orders: "04",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Lindsay Walton",
    phone: "758362649",
    email: "lindsay.walton@example.com",
    balance: "374 038 CFA",
    orders: "04",
    image:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Lindsay Walton",
    phone: "758362649",
    email: "lindsay.walton@example.com",
    balance: "374 038 CFA",
    orders: "04",
    image: null,
  },
  // More people...
];

const UserTable = () => {
  const [Open, setOpen] = useState(false);
  const [ImgUri, setImgUri] = useState("");

  const { users } = useSelector((state) => state.auth);
  const [_, { isLoading }] =
    useValidateOrInvalidateSettingUserMutation();
 
  const setImg = (img) => {
    if (img !== undefined) {
      setImgUri(img);
      setOpen(true);
    }
  };



  return (
    <div className="flex flex-col mt-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Genres
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                  >
                    Recto CNI
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                  >
                    Verso CNI
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                  >
                    Statut
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((person) => (
                  <tr
                    key={person?.email}
                    style={{ opacity: isLoading ? 0.3 : 1 }}
                  >
                    <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className="w-10 h-10 rounded-md"
                            src={person?.image}
                            alt=""
                          />
                          {/* <img className="w-10 h-10 rounded-md" src={person?.profile} alt="" /> */}
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {person?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-gray-500">{person?.phone}</div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-gray-900">{person?.email}</div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-gray-900">
                        {person?.sex === "MALE" ? "HOMME" : "FEMME"}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap ">
                      <div
                        onClick={() => setImg(person?.photoCni?.rectoUrl)}
                        className="max-w-[138px] h-20 border-dashed rounded-md border-gray-400 border-2 p-1 mx-auto "
                      >
                        {isRealValue(person?.photoCni) ? (
                          <img
                            className="object-cover w-full h-full rounded-md"
                            src={person?.photoCni?.rectoUrl}
                            alt=""
                          />
                        ) : (
                          <p className="font-medium text-center text-gray-400 text-md">
                            Aucune image
                          </p>
                        )}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm text-center text-gray-500 whitespace-nowrap ">
                      <div
                        onClick={() => setImg(person?.photoCni.versoUrl)}
                        className="max-w-[138px] h-20 border-dashed rounded-md border-gray-400 border-2 p-1 mx-auto "
                      >
                        {isRealValue(person?.photoCni) ? (
                          <img
                            className="object-cover w-full h-full rounded-md"
                            src={person?.photoCni.versoUrl}
                            alt=""
                          />
                        ) : (
                          <p className="font-medium text-center text-gray-400 text-md">
                            Aucune image
                          </p>
                        )}
                      </div>
                    </td>
                    <td className="relative flex flex-row items-center py-4 pl-3 pr-4 text-sm font-medium text-right justify-evenly whitespace-nowrap sm:pr-6">
                      <ValidateAccount person = {person} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalFlat isOpen={Open} closeModal={() => setOpen(false)} uri={ImgUri} />
    </div>
  );
};

export default UserTable;
