import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
// import { Mutex } from 'async-mutex';
import {  baseUrl, ENDPOINTS, isRealValue, METHODS, refreshToken } from '.';
import { loggedOut, setAuthorization } from '../features/authSlice';
import { token } from '../hook/useAuthentication';

// const mutex = new Mutex();

const baseQuery = retry(fetchBaseQuery({ 
    baseUrl:baseUrl, 
    prepareHeaders: (headers) =>{
        if(isRealValue(token)) headers.set("Authorization", token)
        return headers
    },
}), {maxRetries: 2})

export const baseQueryInterpolatorAuth = async (args, api, extraOptions) => {
    // await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    // console.log('result endpoint', result)
    
    if (result?.error && result?.error?.status === 401 ){
            if (true /*!mutex.isLocked()*/) {
                const release = () => {} //await mutex.acquire()
                try {
                    const refreshResult = await baseQuery({
                        url: `${ENDPOINTS?.REFRESH_TOKEN}/${token}/${refreshToken}`,
                        method: `${METHODS?.POST}`,
                    }, api, extraOptions)   
                    
                    // console.log('result old token', token)
                    // console.log('result refreshResult', refreshResult)

                    if (isRealValue(refreshResult?.data?.accessToken) && refreshResult?.data?.error===false){
                        api.dispatch(setAuthorization(refreshResult.data?.accessToken))
                        result = await baseQuery(args, api, extraOptions)
                    } else {
                        api.dispatch(loggedOut())
                    }
                } finally {
                    release()
                }
            } else {
                // await mutex.waitForUnlock()
                result = await baseQuery(args, api, extraOptions)
            }
    }
    return result
}