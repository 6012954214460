import { createApi } from "@reduxjs/toolkit/query/react";
import { METHODS } from ".";
import { dispatch } from "../..";
import { handleUsers, loggedOut } from "../features/authSlice";
import { getId } from "../hook/useAuthentication";
import { baseQueryInterpolatorAuth } from "./interceptor";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryInterpolatorAuth,
  tagTypes: ["users"],
  endpoints: (builder) => ({
    singOut: builder.mutation({
      query() {
        return {
          url: `auth/users/logout/${getId}/all`,
          method: `${METHODS?.DELETE}`,
        }
      },
      transformResponse: () => {
        dispatch(loggedOut())
      },
    }),
    validateOrInvalidateSettingUser: builder.mutation({
      query({ idUser, validate }) {
        return {
          url: `users/admin/${idUser}?validate=${validate}`,
          method: `${METHODS?.PUT}`,
        }
      },
      invalidatesTags: ['users']
    }),
    allUsers: builder.query({
      query() {
        return {
          url: `users/admin`,
        }
      },
      providesTags: ['users'],
      transformResponse: (r) => {
        dispatch(handleUsers(r))
      },
    }),

  })
})

export const {
  useSingOutMutation,
  useAllUsersQuery,
  useValidateOrInvalidateSettingUserMutation,

} = userApi