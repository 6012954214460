import { createSlice } from "@reduxjs/toolkit";
import { deleteAuth } from "../api";


const initialState = {
    authorization: null,
    refreshToken: null,
    user: null,
    isLogged: false,
   users: [],
   usersFilter: [],
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        handleAuthentication(state, {payload}) {
            const auth = payload
            state.user = auth?.user
            state.authorization = auth?.accessToken
            state.refreshToken = auth?.refreshToken
            state.isLogged = true
        },
        loggedOut(state) {
            state.authorization = null
            state.isLogged = false
            state.refreshToken = null
            state.user = null
            deleteAuth()
        },
        setAuthorization(state, {payload}) {
            const accessToken = payload
            const auth = {
                user: state.user,
                accessToken,
                refreshToken: state.refreshToken
            }
            state.accessToken = accessToken
            state.isLogged= true
            state.authorization = accessToken
        },
        handleUsers(state, {payload}){
            state.usersFilter = payload
            state.users = payload
        },
        handleSearchUsers(state, {payload}){
            if(payload === "") state.users = state.usersFilter
            else {
                state.users = state.usersFilter.filter(u => u.name.toString().toLowerCase().includes(payload.toString().toLowerCase()))
            }
        },
    },
})

export const {
    handleAuthentication,
    loggedOut,
    setAuthorization,
    handleUsers,
    handleSearchUsers

} = authSlice.actions

export default authSlice