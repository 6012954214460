import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignInFormSplit from "./pages/SignInFormSplit";
import UsersTables from "./pages/UsersTables";

const RouterNav = () => (
    <Router>
        <Routes>
            <Route path={`/`} element={<SignInFormSplit />} />
            <Route path={`/Users`} element={<UsersTables />} />
        </Routes>
    </Router>
)

export default RouterNav 