import React, { useState } from 'react'
import UsersTables from './pages/UsersTables'
import SignInFormSplit from './pages/SignInFormSplit'
import RouterNav from './router'
import { AnimatePresence, motion } from 'framer-motion'

const items = [
  {title: "hello here friend", subtitle: "Just a sample example to see framer powers"},
  {title: "hello here friend", subtitle: "Just a sample example to see framer powers"},
  {title: "hello here friend", subtitle: "Just a sample example to see framer powers"},
  {title: "hello here friend", subtitle: "Just a sample example to see framer powers"},
]

const App = () => {

  return <RouterNav />
}

export default App